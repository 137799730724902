import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { toTitleCase } from "../helpers";

function CandidateCard({ candidate, showAvatars }) {
  return (
    <div
      key={candidate.id}
      className="first:ml-0 last:mr-0 md:px-1 md:w-1/6 md:max-w-sm md:mt-4 md:mb-0 my-2 text-ellipsis overflow-hidden"
    >
      {showAvatars && candidate.image && (
        <Link to={`/candidate/${candidate.slug}`}>
          <img
            className="candidate-image w-full h-3/4 object-cover object-center rounded-md border border-gray-200 shadow-lg"
            src={candidate.image}
            alt={candidate.name + "'s image"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/images/person-icon.png";
            }}
          />
        </Link>
      )}

      <div className="py-4 px-6 pl-0">
        <Link to={`/candidate/${candidate.slug}`}>
          <h1 className="text-2xl font-semibold text-gray-800 capitalize">
            {toTitleCase(candidate.name)}
          </h1>
        </Link>
      </div>
    </div>
  );
}

CandidateCard.propTypes = {
  showAvatars: PropTypes.bool,
  candidate: PropTypes.object.isRequired,
};

export default CandidateCard;
