import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, useStaticQuery } from "gatsby";
// import {
//   BookOpenIcon,
//   UserCircleIcon,
//   ArrowRightIcon,
// } from "@heroicons/react/outline";
import CandidateCards from "../components/candidate-cards";

function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      ngelectionapi {
        electionById(id: 1) {
          summary
          description
          candidates {
            id
            slug
            name
            summary
            image
          }
        }

        elections {
          id
          year
          name
          candidates {
            slug
            name
            image
          }
        }
      }
    }
  `);

  const election = data.ngelectionapi.electionById;

  // const ctas = [
  //   {
  //     title: "How To Vote",
  //     icon: BookOpenIcon,
  //     description: `Not sure how to get started with voting? 
  //       It's easier than you think.`,
  //     link: "",
  //   },
  //   {
  //     title: "Know Your Candidates",
  //     icon: UserCircleIcon,
  //     description: "Learn more about candidates before you vote!",
  //     link: "",
  //   },
  // ];

  return (
    <Layout>
      <SEO keywords={[`elections`, `nigeria`]} title="Home" />

      <h2 className="text-2xl font-bold border-b-4 border-black py-3 mb-18">
        2023 General Elections
      </h2>

      <p className="my-3 md:mb-10 mb-5">{election.summary}</p>

      {/* <div className="md:my-10 my-8">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          {ctas.map((cta, i) => (
            <div key={i} className="relative border rounded-md p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-600 text-white">
                  <cta.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  {cta.title}
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                {cta.description}
                <p className="text-sm mt-2">
                  <a
                    href={cta.link}
                    className="flex text-green-600 hover:text-green-700 space-x-1"
                  >
                    <span>Learn More</span>{" "}
                    <ArrowRightIcon className="h-5 hover:ml-2" />
                  </a>
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div> */}

      <h2 className="text-xl font-bold border-b-4 border-black py-3 mb-18">
        Presidential Candidates
      </h2>
      <CandidateCards candidates={election.candidates} showAvatars />
    </Layout>
  );
}

export default IndexPage;
