import React from "react";
import CandidateCard from "../components/candidate-card";
import PropTypes from "prop-types";

function CandidateCards({ candidates, showAvatars }) {
  return (
    <div className="flex flex-wrap">
      {candidates.map((c) => {
        return (
          <CandidateCard key={c.id} candidate={c} showAvatars={showAvatars} />
        );
      })}
    </div>
  );
}

CandidateCards.propTypes = {
  showAvatars: PropTypes.bool,
  candidates: PropTypes.array.isRequired,
};

export default CandidateCards;
